import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './Image.module.scss'
import 'react-lazy-load-image-component/src/effects/blur.css';
 
const Image = ({ alt, src, className, ...props}) => {
  console.log(alt, src, className, props)

  return (
    <div className={`${styles.imageContainer} ${className}`}>
      <LazyLoadImage
        alt={alt}
        effect="blur"
        src={src} />
    </div>
  )
}

export default Image