import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../layout/index'
import config from '../../data/SiteConfig'
import styles from './page.module.scss'

import Image from '../elements/Image'

import { Row, Typography, Button } from 'antd'
const { Title } = Typography

const NotFoundPage = () => (
  <Layout>
    <main className={styles.pageContainer}>
      <Helmet title={`404 - Page Introuvable | ${config.siteTitle}`} />
      <Row justify="center"><Title>404 Page Introuvable</Title></Row>
      <Image alt="404 Lost" src="https://source.unsplash.com/1200x600/?lost"/>
      <Row justify="center" style={{ marginTop: 20 }}><Link to="/"><Button>Retour à l'accueil</Button></Link></Row>
    </main>
  </Layout>
)

export default NotFoundPage
